import React from 'react';
import { withTranslation } from 'react-i18next';
class deliveryAttempts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  };

  render () {
    const { t } = this.props;
    return (
      <div className="flex flex-col gap-4">
        {
          this.props.deliveryAttempts.length === 0 && this.props.order.customer.address.zone_verified &&
          <div className="bg-[#DDDDDD] rounded-lg md:py-4 py-3 md:px-6 px-4">
            <p className="md:text-lg leading-tight font-medium text-[#565656]">{ t('Delivery is delayed by 1 day to adjust it to the correct zone.') }</p>
          </div>
        }
        {
          this.props.deliveryAttempts.map((item, index)=> (
            <div key={index} className="bg-[#DDDDDD] rounded-lg md:py-4 py-3 md:px-6 px-4">
              <div className="md:text-lg text-sm font-medium">
                <p className="leading-tightm sm:mb-2 mb-1.5">
                  <span className="text-[#737373]">{ t('Delivery Attempt at ')}</span>
                  <span className="text-[#0D0D0D]">{ t(item.created.split('T')[0]) }</span>
                </p>
                {
                  item.comment === 'Customer rescheduled'?
                  <p className="text-[#404040]">{ t(item.comment + this.props.order.preferred_date) }</p>
                  :
                  item.comment === 'Customer wants to Change Address' ?
                  <p className="text-[#404040]">{ t(item.comment + this.props.order.customer.address.line_1) }</p>
                  :
                  <p className="text-[#404040] leading-tight">{ t(item.comment) }</p>
                }
              </div>
            </div>
          ))
          }
      </div>
    )
  }
};
export default withTranslation()(deliveryAttempts)
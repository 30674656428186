import { useTranslation } from 'react-i18next';
import common from '../common'
import { useEffect, useState } from 'react';
import i18n from '../i18n';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function Status({order, trackingType}) {
  const { t } = useTranslation();
  const [doneStatus, setDoneStatus] = useState([]);
  const [currentStatus, setCurrentStatus] = useState('');
  const statusesValue = [
    {
      value: 'created',
      name: 'Created',
    },
    {
      value: 'picked_up',
      name: 'Picked up',
    },
    {
      value: 'in_transit',
      name: 'En Route',
    }
  ]
  const [statuses, setStatuses] = useState(statusesValue);
  const cashCollectionStatusesValue = [
    {
      value: 'created',
      name: 'Created',
    },
    {
      value: 'collection_requested',
      name: 'Request Received',
    },
    {
      value: 'out_for_collection',
      name: 'Out for collecting',
    },
    {
      value: 'collected',
      name: 'Collected',
    }
  ]
  const [cashCollectionStatuses] = useState(cashCollectionStatusesValue);
  const changeOrderStatus = () => {
    let done = []
    const statusValues = [...statusesValue]
    switch (trackingType) {
    case 'delivery-order':
      statusValues.push(
        {
          value: 'out_for_delivery',
          name: 'Out for Delivery',
        },
        {
          value: 'delivered',
          name: 'Delivered',
        })
        statusValues.forEach(element => {
          element.statusDate = element.value === 'created' ? order.created : element.value === 'picked_up' ? order.pickup_date : element.value === 'delivered' || element.value === 'returned' ? order.delivered_date === null ? order.estimated_date : order.delivered_date  : ''
        })
        setStatuses(statusValues)
      break
    case 'return':
      statusValues.push(
        {
          value: 'out_for_return',
          name: 'Out for return',
        },
        {
          value: 'returned',
          name: 'Returned',
        })
        setStatuses(statusValues)
      break
    case 'cash-collection':
      setStatuses(cashCollectionStatuses)
      break
    default:
      break
    }
    setCurrentStatus(order.status)
    switch (order.status) {
    case 'created':
      done = []
      setDoneStatus(done)
      break
    case 'pickup_requested':
    case 'pickup_rescheduled':
      done = []
      setDoneStatus(done)
      setCurrentStatus('created')
      break
    case 'out_for_pickup':
      done = []
      setDoneStatus(done)
      setCurrentStatus('created')
      break
    case 'return_requested':
      done = []
      setDoneStatus(done)
      setCurrentStatus('created')
      break
    case 'picked_up':
      done.push('created', 'out_for_pickup', 'pickup_requested', 'pickup_rescheduled')
      setDoneStatus(done)
      break
    case 'collection_requested':
      done.push('created')
      setDoneStatus(done)
      break
    case 'out_for_collection':
      done.push('collection_requested')
      setDoneStatus(done)
      break
    case 'collected':
      done.push('collection_requested', 'out_for_collection')
      setDoneStatus(done)
      break
    case 'out_for_delivery':
      done.push('created', 'picked_up', 'in_transit')
      setDoneStatus(done)
      break
    case 'out_for_return':
      done.push('created', 'picked_up', 'in_transit')
      setDoneStatus(done)
      break
    case 'delivered':
      done.push('created', 'picked_up', 'in_transit', 'out_for_delivery')
      setDoneStatus(done)
      break
    case 'returned':
      done.push('created', 'picked_up', 'in_transit', 'out_for_return')
      setDoneStatus(done)
      break
    case 'cancelled':
      done.push('created')
      setDoneStatus(done)
      break
    default:
      done.push('created', 'picked_up')
      setDoneStatus(done)
      setCurrentStatus(trackingType === 'cash-collection' ? 'collection_requested' : 'in_transit')
      break
    }
  }
  useEffect(() => {
    if (order && order.status) {
      changeOrderStatus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.status])

  return (
    <div className="card xl:py-7 xl:px-14 p-6 h-full">
      <div className="flex items-center justify-between gap-2">
        <p className="text-[#404040] font-medium text-xl">{ t('Shipment Timeline') }</p>
        <div className="md:block hidden text-lg font-medium items-center justify-end">
          <span className="text-[#AFBFD5]">{ t('Shipper Name') }: </span>
          <span className="text-blue">{ order.merchant.name }</span>
        </div>
      </div>
      <div className="flex md:gap-0 gap-4 justify-between items-center mt-8">
        {
          i18n.language === 'ar' ?
          <img className="shrink-0" style={{ transform: "rotateY(180deg)" }} src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/Favicon.png" alt="ShipBlu Icon" width={75} height={111} />
          : <img className="shrink-0" src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/Favicon.png" alt="ShipBlu Icon" width={75} height={111} />
        }
        <svg className="md:block hidden rotate w-full h-12" viewBox="0 0 100 100" preserveAspectRatio="none">
          <path d="M 0 50 q 25 70 50 0" fill="none" stroke="#1C5BFE" strokeWidth="1" strokeDasharray="15" vectorEffect="non-scaling-stroke" />
          <path d="M 50 50 q 25 -70 50 0" stroke="#1C5BFE" strokeWidth="1" fill="none" strokeDasharray="15" vectorEffect="non-scaling-stroke"/>
        </svg>
        <div className={`w-fit whitespace-nowrap sm:p-4 p-2 border border-solid border-[#8599B3] rounded-md ${i18n.language === 'en' ? 'border-l-8 border-l-[#1C5BFE]' : 'border-r-8 border-r-[#1C5BFE]'}`}>
        {
          order.status === 'created' || order.status === 'pickup_requested' || order.status === 'pickup_rescheduled' ?
          <p className="text-center font-medium text-blue">{t('To be determined after picking up your order')}</p> :
          <>
            <p className="sm:text-base text-sm mb-1 font-normal text-[#737373]">{t('Estimated Delivery Date')}</p>
            <p className="sm:text-lg font-medium text-blue">{ order.preferred_date ? common.gettingDate(order.preferred_date, true) : order.estimated_date ? common.gettingDate(order.estimated_date, true) : 'N/A' }</p>
          </>
        }
        </div>
      </div>
      <div className="md:hidden block text-lg font-medium items-center mt-6 justify-end">
        <span className="text-[#AFBFD5]">{ t('Shipper Name:') } </span>
        <span className="text-blue">{ order.merchant.name }</span>
      </div>
      <div className="md:mt-12 mt-8 flex md:flex-row flex-col justify-between">
        {
          statuses.map((status, index) => (
            <div key={index} className="w-full relative">
              <div className={`flex md:flex-col md:h-auto ${ index < statuses.length - 1 ? 'h-20' : ''}`}>
                <div className={`${index < statuses.length - 1 ? '' : 'hidden'} ${i18n.language === 'en' ? 'left-2.5' : 'right-2.5'} relative md:left-auto top-3.5 md:border-t border-t-0 md:border-l-0 border-l border-solid border-[#8599B3]`}></div>
                <p className={`bg-white md:mt-0 mt-0.5 relative flex w-5 md:h-auto md:block h-5 text-center ${doneStatus.includes(status.value) ? 'text-blue' : statuses[index].value === currentStatus ? 'text-[#409C31]' : 'text-[#737373]'}`}>
                {
                  statuses[index].value === currentStatus ? 
                  (
                    <RadioButtonCheckedIcon fontSize="small" style={{color: statuses[index].value === 'in_transit' && order.on_hold !== null && statuses[index].value === currentStatus ? '#FF9F43':''}} />
                  ) : 
                  (
                    <CheckCircleIcon fontSize="small" />
                  )
                }
                </p>
                <div className="px-1">
                  <p className="text-darkblue">
                    { 
                      statuses[index].value === 'in_transit' && order.on_hold !== null && statuses[index].value === currentStatus ? <span style={{color:'#FF9F43'}}> {t('on hold')} </span> : t(status.name)
                    }
                  </p>
                  {
                    order.on_hold !== null && statuses[index].value === 'in_transit' && statuses[index].value === currentStatus &&
                    <p className="text-sm leading-none text-[#8599B3]">{ t('Get back to your seller to resolve the issue') }</p>
                  }
                  {
                    status.statusDate &&
                    <p className="text-sm leading-none text-[#8599B3]">{ t(common.gettingDate(status.statusDate)) }</p>
                  }
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          'القاهرة - Cairo': 'Cairo',
          'Cairo - القاهرة': 'Cairo',
          'الاسكندرية - Alexandria': 'Alexandria',
          'الساحل الشمالي - North Coast': 'North Coast',
          'الدقهلية  - Al-Dakahlia': 'Al-Dakahlia',
          'القليوبية - Qalyubia': 'Qalyubia',
          'المنوفية - Monoufia': 'Monoufia',
          'El Gharbia - الغربية': 'El Gharbia',
          'البحيرة - Al-Beheira': 'Al-Beheira',
          'Kafr El Sheikh - كفر الشيخ': 'Kafr El Sheikh',
          'اسيوط -Assiut': 'Assiut',
          'الفيوم - Fayoum': 'Fayoum',
          'بني سويف - Beni Suef': 'Beni Suef',
          'الاسماعيلية - Ismailiya': 'Ismailiya',
          'السويس - Suez': 'Suez',
          'البحر الأحمر- Al-Bahr Al-Ahmar': 'Al-Bahr Al-Ahmar',
          'القاهرة الجديدة - New Cairo': 'New Cairo',
          'وسط البلد - Downtown Cairo': 'Downtown Cairo',
          'اكتوبر - October': 'October',
          'وسط البلد والجمرك - Downtown Alex & Gomrok': 'Downtown Alex & Gomrok',
          'Agamy & El-Amreya - العجمي والعامرية': 'Agamy & El-Amreya',
          'شرق الاسكندرية والمنتزة - East Alex & Montazah': 'East Alex & Montazah',
          'الساحل - Sahel': 'Sahel',
          'المنصورة - Mansoura': 'Mansoura',
          'بنها - Banha': 'Banha',
          'Shebeen Al Kom - شبين الكوم': 'Shebeen Al Kom',
          'Mahala El Kobra - المحلة الكبري': 'Mahala El Kobra',
          'طنطا - Tanta': 'Tanta',
          'دمنهور - Damanhour': 'Damanhour',
          'اسيوط - Assiut': 'Assiut',
          'الغردقة - Hurghada': 'Hurghada',
          'El Gouna - الجونة': 'El Gouna',
          'التجمع الثالث - 3rd Settlement': '3rd Settlement',
          'التجمع الخامس - 5th Settlement': '5th Settlement',
          '5th Settlement - District No 1': '5th Settlement - District No 1',
          '5th Settlement - District No 2': '5th Settlement - District No 2',
          '5th Settlement - District No 3': '5th Settlement - District No 3',
          '5th Settlement - District No 4': '5th Settlement - District No 4',
          '5th Settlement - District No 5': '5th Settlement - District No 5',
          'الشويفات - El-Shouyfat': 'El-Shouyfat',
          'الدبلوماسيين - El-Diplomasyeen': 'El-Diplomasyeen',
          'القطامية - El-Kattameya': 'El-Kattameya',
          'ارابيلا - Arabella': 'Arabella',
          'غرب الجولف - Gharb El Golf': 'Gharb El Golf',
          'Al Rehab - الرحاب': 'Al Rehab',
          'الياسمين 1 - El-Yasmeen 1': 'El-Yasmeen 1',
          'الياسمين 2 - El-Yasmeen 2': 'El-Yasmeen 2',
          'الياسمين 3 - El-Yasmeen 3': 'El-Yasmeen 3',
          'الياسمين 4 - El-Yasmeen 4': 'El-Yasmeen 4',
          'الياسمين 5 - El-Yasmeen 5': 'El-Yasmeen 5',
          'الياسمين 6 - El-Yasmeen 6': 'El-Yasmeen 6',
          'الياسمين 7 - El-Yasmeen 7': 'El-Yasmeen 7',
          'الياسمين 8 - El-Yasmeen 8': 'El-Yasmeen 8',
          'البنفسج 1 - El-Banafseg 1': 'El-Banafseg 1',
          'البنفسج 2 - El-Banafseg 2': 'El-Banafseg 2',
          'البنفسج 3 - El-Banafseg 3': 'El-Banafseg 3',
          'البنفسج 4 - El-Banafseg 4': 'El-Banafseg 4',
          'البنفسج 5 - El-Banafseg 5': 'El-Banafseg 5',
          'البنفسج 6 - El-Banafseg 6': 'El-Banafseg 6',
          'البنفسج 7 - El-Banafseg 7': 'El-Banafseg 7',
          'البنفسج 8 - El-Banafseg 8': 'El-Banafseg 8',
          'البنفسج 9 - El-Banafseg 9': 'El-Banafseg 9',
          'البنفسج 10 - El-Banafseg 10': 'El-Banafseg 10',
          'البنفسج 11 - El-Banafseg 11': 'El-Banafseg 11',
          'البنفسج 12 - El-Banafseg 12': 'El-Banafseg 12',
          'التجمع الأول - 1st Settlement': '1st Settlement',
          '1st Settlement - North Youth Housing': '1st Settlement - North Youth Housing',
          '2nd Settlement - South Youth Housing': '2nd Settlement - South Youth Housing',
          'كايرو فستيفال سيتي - Cairo Festival City': 'Cairo Festival City',
          'شرق الأكاديمية - Sharq El Academia': 'Sharq El Academia',
          'جنوب الاكاديمية - Ganoob Al Academia - Area A': 'Ganoob Al Academia - Area A',
          'جنوب الاكاديمية - Ganoob Al Academia - Area B': 'Ganoob Al Academia - Area B',
          'جنوب الاكاديمية - Ganoob Al Academia - Area C': 'Ganoob Al Academia - Area C',
          'جنوب الاكاديمية - Ganoob Al Academia - Area D': 'Ganoob Al Academia - Area D',
          'جنوب الاكاديمية - Ganoob Al Academia - Area\u00a0E': 'Ganoob Al Academia - Area\u00a0E',
          'جنوب الاكاديمية - Ganoob Al Academia - Area F': 'Ganoob Al Academia - Area F',
          'منطقة المستثمرين الجنوبية - South Investors Area': 'South Investors Area',
          'منطقة المسثمرين الشمالية - North Investors Area': 'North Investors Area',
          'الأندلوس - El Andalous': 'El Andalous',
          'اللوتس - El-Lotus': 'El-Lotus',
          'النرجس 1 - El-Narges 1': 'El-Lotus 1',
          'النرجس 2 - El-Narges 2': 'El-Lotus 2',
          'النرجس 3 - El-Narges 3': 'El-Lotus 3',
          'النرجس 4 - El-Narges 4': 'El-Lotus 4',
          'النرجس 5 - El-Narges 5': 'El-Lotus 5',
          'النرجس 6 - El-Narges 6': 'El-Lotus 6',
          'النرجس 7 - El-Narges 7': 'El-Lotus 7',
          'النرجس 8 - El-Narges 8': 'El-Lotus 8',
          'المنطقة الأولي - Area 1': 'Area 1',
          'المنطقة الثالثة - Area 3': 'Area 3',
          'أبو الهول - Abo El Houl': 'Abo El Houl',
          'العاشر من رمضان - 10th of Ramadan': '10th of Ramadan',
          'El Shorouk - الشروق': 'El Shorouk',
          'مدينة المستقبل - Future City': 'Future City',
          'مدينتي - Madinaty': 'Madinaty',
          'العبور - Obour': 'Obour',
          'مدينة الطلائع - El Talae3 City': 'El Talae3 City',
          'هيلوبوليس الجديدة - New Heliopolis City': 'New Heliopolis City',
          'مدينة بدر - Badr City': 'Badr City',
          'جمعية أحمد عرابي - Ahmed Oraby Association': 'Ahmed Oraby Association',
          'Al Manial - المنيل': 'Al Manial',
          'جاردن سيتي - Garden City': 'Garden City',
          'الزمالك - Zamalek': 'Zamalek',
          'Masr El Kadema - مصر القديمة': 'Masr El Kadema',
          'عابدين - Abdeen': 'Abdeen',
          'بولاق - Bulaq': 'Bulaq',
          'العجوزة - Agouza': 'Agouza',
          'الدقي - Dokki': 'Dokki',
          'الجيزة - Giza': 'Giza',
          'Giza - الجيزة': 'Giza',
          'Damanhour - دمنهور': 'Damanhour',
          'المهندسين - Mohandeseen': 'Mohandeseen',
          'ميت عقبة - Mit Akaba': 'Mit Akaba',
          'Ard El Lewa - ارض اللوا': 'Ard El Lewa',
          'فيصل - Faisal': 'Faisal',
          'الهرم - Haram': 'Haram',
          'حدائق الأهرام - Hadayek El Haram': 'Hadayek El Haram',
          'امبابة - Imbaba': 'Imbaba',
          'El Matareya - المطرية': 'El Matareya',
          'El Qobbah - القبة': 'El Qobbah',
          'Al Marj - المرج': 'Al Marj',
          'Road El Farag - روض الفرج': 'Road El Farag',
          'العباسية - Abbassia': 'Abbassia',
          'Al Wayli - الوايلي': 'Al Wayli',
          'Al Daher': 'Al Daher',
          'شبرا - Shubra': 'Shubra',
          'New Cities - المدن الجديدة': 'New Cities',
          '6 October - السادس من أكتوبر': '6 October',
          'بلطيم - Balteem': 'Balteem',
          'Madinet Al-Salam - مدينة السلام': 'Madinet Al-Salam',
          'Shubra El Kheima - شبرا الخيمة': 'Shubra El Kheima',
          'Al Muqattam - المقطم': 'Al Muqattam',
          'الهضبة الوسطي - El-Hadaba El-Wosta': 'El-Hadaba El-Wosta',
          'حلوان - Helwan': 'Helwan',
          'المعادي - Maadi': 'Maadi',
          'شمال القاهرة - Uptown Cairo': 'Uptown Cairo',
          'الزيتون - El-Zaytoun': 'El-Zaytoun',
          'جسر السويس - Gesr elsuez': 'Gesr elsuez',
          'عين شمس - Ain Shams': 'Ain Shams',
          'مدينة نصر - Nasr City': 'Nasr City',
          'شيراتون - Sheraton': 'Sheraton',
          'مصر الجديدة - Heliopolis': 'Heliopolis',
          'Abu Rawash - ابورواش': 'Abu Rawash',
          'Alex Desert Road (Before Toll Station)': 'Alex Desert Road (Before Toll Station)',
          'Alex Desert Road (After Toll Station)': 'Alex Desert Road (After Toll Station)',
          'New Giza': 'New Giza',
          'القرية الذكية - Smart Village': 'Smart Village',
          'Pyramids Heights': 'Pyramids Heights',
          'Garana Residence': 'Garana Residence',
          'Azizia Villas': 'Azizia Villas',
          'Katr El Nada': 'Katr El Nada',
          'دريم لاند - Dream Land': 'Dream Land',
          'حدايق الاهرام - Pyramids Gardens': 'Pyramids Gardens',
          '6th of October City - اكتوبر': '6th October City',
          '6th of October City - District 1': '6th of October City - District 1',
          '6th of October City - District 2': '6th of October City - District 2',
          '6th of October City - District 3': '6th of October City - District 3',
          '6th of October City - District 4': '6th of October City - District 4',
          '6th of October City - District 5': '6th of October City - District 5',
          '6th of October City - District 6': '6th of October City - District 6',
          '6th of October City - Al Bashayer District': '6th of October City - Al Bashayer District ',
          '6th of October City - District 8': '6th of October City - District 8',
          '6th of October City - District 10': '6th of October City - District 10',
          '6th of October City - District 11': '6th of October City - District 11',
          '6th of October City - District 12': '6th of October City - District 12',
          '6th of October City - Youth Residence': '6th of October City - Youth Residence',
          '6th of October City - Al Motameyz District': '6th of October City - Al Motameyz District',
          '6th of October City - West Somid': '6th of October City - West Somid',
          'Sheikh Zayed City - الشيخ زايد': 'Sheikh Zayed City ',
          'Sheikh Zayed City - El Rabwa Compound': 'Sheikh Zayed City - El Rabwa Compound ',
          'Sheikh Zayed City - Westown Residence': 'Sheikh Zayed City - Westown Residence',
          'Sheikh Zayed City - Sodic Allegria': 'Sheikh Zayed City - Sodic Allegria',
          'Sheikh Zayed City - Casa Compound': 'Sheikh Zayed City - Casa Compound',
          'Sheikh Zayed City - Beverly Hills Zayed': 'Sheikh Zayed City - Beverly Hills Zayed',
          'Sheikh Zayed City - District 1': 'Sheikh Zayed City - District 1',
          'Sheikh Zayed City - District 2': 'Sheikh Zayed City - District 2',
          'Sheikh Zayed City - District 3': 'Sheikh Zayed City - District 3',
          'Sheikh Zayed City - District 4': 'Sheikh Zayed City - District 4',
          'Sheikh Zayed City - District 5': 'Sheikh Zayed City - District 5',
          'Sheikh Zayed City - District 7': 'Sheikh Zayed City - District 7',
          'Sheikh Zayed City - District 8': 'Sheikh Zayed City - District 8',
          'Sheikh Zayed City - District 9': 'Sheikh Zayed City - District 9',
          'Sheikh Zayed City - District 10': 'Sheikh Zayed City - District 1',
          'Sheikh Zayed City - District 11': 'Sheikh Zayed City - District 11',
          'Sheikh Zayed City - District 12': 'Sheikh Zayed City - District 12',
          'Sheikh Zayed City - District 14': 'Sheikh Zayed City - District 14',
          'Sheikh Zayed City - District 16': 'Sheikh Zayed City - District 16',
          'El Hadara - الحضرة': 'El Hadara',
          'El Labban - اللبان': 'El Labban',
          'El Shatby - الشاطبي': 'El Shatby',
          'كرموز - Karmoz': 'Karmoz',
          'Kom El Deka - كوم الدقة': 'Kom El Deka',
          'Mahatet El Raml - محطة الرمل': 'Mahatet El Raml',
          'محرم بيك - Moharam Bek': 'Moharam Bek',
          'وسط البلد - Downtown Alexandria': 'Downtown Alexandria',
          'El Atareen - العطارين': 'El Atareen',
          'El Azarita - الازاريطة': 'El Azarita',
          'El Ibrahimiya - الابراهيمية': 'El Ibrahimiya',
          'بحري - Bahary': 'Bahary',
          'El Anfoushi - الانفوشي': 'El Anfoushi',
          'El Gomrok - الجمرك': 'El Gomrok',
          'El Mansheya - المنشية': 'El Mansheya',
          'El Qabary - القباري': 'El Qabary',
          'El Wardeyan - الورديان': 'El Wardeyan',
          'العجمي - Agamy': 'Agamy',
          'أبو يوسف - Abou Youssef': 'Abou Youssef',
          'العامرية - El-Amreya': 'El-Amreya',
          'البيطاش - El-Bitash': 'El-Bitash',
          'الديخيلة - El-Dekhela': 'El-Dekhela',
          'الهانوفيل - El-Hanoveel': 'El-Hanoveel',
          'المكس - El-Max': 'El-Max',
          'أكتوبر - October': 'October',
          'باكوس - Bakos': 'Bakos',
          'بولكلي - Bolkly': 'Bolkly',
          'كامب شيزار - Camp Chezar': 'Camp Chezar',
          'كليوباترا - Cleopatra': 'Cleopatra',
          'El Saraya - السراية': 'El Saraya',
          'فليمنج - Fleming': 'Fleming',
          'جناكلس - Gianaclis': 'Gianaclis',
          'جليم - Glim': 'Glim',
          'كقر عبده - Kafr Abdu': 'Kafr Abdu',
          'لوران - Louran': 'Louran',
          'رشدي - Roshdy': 'Roshdy',
          'سابا باشا - Saba Basha': 'Saba Basha',
          'سان ستيفانو - San Stefano': 'San Stefano',
          'سيدي جابر - Sidi Gaber': 'Sidi Gaber',
          'سموحة - Smouha': 'Smouha',
          'سبورتنج - Sporting': 'Sporting',
          'ستانلي - Stanley': 'Stanley',
          'ثروت - Tharwat': 'Tharwat',
          'زيزينيا - Zezenia': 'Zezenia',
          'Abu Kir - ابوقير': 'Abu Kir',
          'El Asafra - العصافرة': 'El Asafra',
          'El Maamora - المعمورة': 'El Maamora',
          'El Mandara - المندرة': 'El Mandara',
          'El Montaza - المنتزة': 'El Montaza',
          'El Soyof - السيوف': 'El Soyof',
          'ميامي - Miami': 'Miami',
          'سيدي بشر - Sidi Bishr': 'Sidi Bishr',
          'فكتوريا - Victoria': 'Victoria',
          'عزبة الشال': 'عزبة الشال',
          'مدينة مبارك': 'مدينة مبارك',
          'سندوب': 'سندوب',
          'مدينة الفردوس': 'مدينة الفردوس',
          'مدينة السلام': 'مدينة السلام',
          'حي الجامعة': 'حي الجامعة',
          'دسوق - Desouq': 'Desouq',
          'الرياض - El-Reyad': 'El-Reyad',
          'فوه - Fuwwah': 'Fuwwah',
          'حمول - Hamool': 'Hamool',
          'كفر الشيخ - Kafr Alsheikh': 'Kafr Alsheikh',
          'مطوبس - Mutubas': 'Mutubas',
          'قلين - Qeleen': 'Qeleen',
          'سيدي سالم - Sidi Salem': 'Sidi Salem',
          'ابنوب - Abanoub': 'Abanoub',
          'Abo Teg - ابوتيج': 'Abo Teg',
          'ديروط - Dayrout': 'Dayrout',
          'El Badary - البداري': 'El Badary',
          'El Fath - الفتح': 'El Fath ',
          'El Ghanaym - الغنايم': 'El Ghanaym ',
          'El Qawsaia - القوصية': 'El Qawsaia',
          'منفلوط - Manfalout': 'Manfalout',
          'اسيوط الجديدة - New Assuit': 'New Assuit',
          'صدفا - Sadfa': 'Sadfa',
          'ساحل سليم - Sahel Selim': 'Sahel Selim',
          'اطسا - Atsa': 'Atsa',
          'ابشواي - Ebsheway': 'Ebsheway',
          'طمية - Tamya': 'Tamya',
          'يوسف الصديق - Yousef Elsedeq': 'Yousef Elsedeq',
          'ببا - Biba': 'Biba',
          'الفشن - Elfashn': 'Elfashn',
          'الواسطى - Elwasty': 'Elwasty',
          'إهناسيا - Ihnasya': 'Ihnasya',
          'ناصر - Nasser': 'Nasser',
          'بني سويف الجديدة - New Beni Suef': 'New Beni Suef',
          'سمسطا - Samsta': 'Samsta',
          'أبو عطوة - Abu Atwa': 'Abu Atwa',
          'أبو خليفة - Abu Khalifah': 'Abu Khalifah',
          'أبو صوير - Abu Soweir': 'Abu Soweir',
          'أبو سلطان - Abu sultan': 'Abu sultan',
          'التل الكبير - Al Tall Al kabir': 'Al Tall Al kabir',
          'القصاصين - El Kasasin': 'El Kasasin',
          'الصالحية الجديدة - El Saleheya Al gadida': 'El Saleheya Al gadida',
          'فايد - Fayed': 'Fayed',
          'الحي الأول - Hay 1': 'Hay 1',
          'الحي الثاني - Hay 2': 'Hay 2',
          'الحي الثالث - Hay 3': 'Hay 3',
          'الكيلو 11 - Kilo 11': 'Kilo 11',
          'قنطرة غرب - Qantara Gharab': 'Qantara Gharab',
          'قنطرة شرق - Qantara Shark': 'Qantara Shark',
          'سرابيوم - Sarabium': 'Sarabium',
          'أبو رديس - Abu Rudeis': 'Abu Rudeis',
          'أبو زنيمة - Abu Zenima': 'Abu Zenima',
          'منطقة السويس - Al Suez District': 'Al Suez District',
          'العدبية - Al-Adabya': 'Al-Adabya',
          'عتاقة - Ataka District': 'Ataka District',
          'السلام - El Salam District': 'El Salam District',
          'الأربعين - El-Arbeen District': 'El-Arbeen District',
          'الجناين - Elganaien District': 'Elganaien District',
          'فيصل - Faisal District': 'Faisal District',
          'El-Hawamdeya - الحوامدية': 'El-Hawamdeya',
          'ElBadrasheen - البدرشين': 'ElBadrasheen ',
          'الحوامدية - El-Hawamdeya': 'El-Hawamdeya',
          'البدرشين - ElBadrasheen': 'ElBadrasheen',
          'Search/copy your location..': 'Search/copy your location..',
          'Locate me!': 'Locate me!',
          'From: ': 'From: ',
          'To: ': 'To: ',
          'we have an eye on your shipment': 'we have an eye on your shipment',
          'Here you can track your shipment, and reschedule your delivery date.':  'Here you can track your shipment, and reschedule your delivery date.',
          'Estimated Delivery Date': 'Estimated Delivery Date',
          'Locate on map': 'Locate on map',
          'Wanna decide when to expect your order?': 'Wanna decide when to expect your order?',
          'Kindly choose an appropriate time slot that fits your availability!': 'Kindly, choose an appropriate time slot that fits your availability!',
          'Wanna receive your order on a different day?': 'Wanna receive your order on a different day?',
          'Kindly choose another day to receive your order on!': 'Kindly, choose another day to receive your order on!',
          'Locate your Address!': 'Locate your Address!',
          'Help us find your address below so that we don\'t have to call you for directions!': 'Help us find your address below so that we don\'t have to call you for directions!',
          'You can select more than one!': 'You can select more than one!',
          'You\'ve rescheduled your shipment\'s delivery window to:': 'You\'ve rescheduled your shipment\'s delivery window to:',
          'You\'ve rescheduled your delivery date to:': 'You\'ve rescheduled your delivery date to:',
          'Your location is:': 'Your location is:',
          'Your location is now updated': 'Your location is now updated',
          'Save Address': 'Save Address',
          'what3words (optional)': 'what3words (optional)',
          'Enter your shipment tracking number': 'Enter your shipment tracking number',
          'Track': 'Track',
          'The tracking number field format is invalid': 'The tracking number field format is invalid',
          'Between': 'Between',
          'No delivery window chosen': 'No delivery window chosen',
          'or': 'or',
          'And': 'And',
          'Picked up': 'Picked up',
          'En Route': 'En Route',
          'Out for Delivery': 'Out for Delivery',
          'Delivered': 'Delivered',
          'Out for Return': 'Out for Return',
          'Returned': 'Returned',
          'Tracking number does not exist!': 'Tracking number does not exist!',
          'You can change your order information after': 'You can change your order information after',
          'entering the': 'entering the',
          'pin code': 'pin code',
          'provided in the': 'provided in the',
          'Whatsapp confirmation message': 'Whatsapp confirmation message',
          'Change order details': 'Change order details',
          'You can change the details of your order through the link sent to your WhatsApp number , or by contacting the shipper': 'You can change the details of your order through the link sent to your WhatsApp number , or by contacting the shipper',
          'Got It': 'Got It',
          'Created': 'Created',
          'Packages': 'Packages',
          'Shipper': 'Shipper',
          'Save to your phone': 'Save to your phone',
          'Our agent will scan this QR Code from your device once he reaches your location.': 'Our agent will scan this QR Code from your device once he reaches your location.',
          'Do not send it to him before his arrival.': 'Do not send it to him before his arrival.',
          'Valu installments is now available at your doorstep': 'Valu installments is now available at your doorstep',
          'Your customers can now pay in installments for up to 60 months using Valu through myBlu app.': 'Your customers can now pay in installments for up to 60 months using Valu through myBlu app.',
          'Track, Pay, and Edit:': 'Track, Pay, and Edit:',
          'Essentials': 'Essentials',
          'for Your Order Management': 'for Your Order Management',
          'Instant': 'Instant',
          'Tracking Updates': 'Tracking Updates',
          'Stay in the loop with real-time updates on your order\'s shipping progress, right at your fingertips.': 'Stay in the loop with real-time updates on your order\'s shipping progress, right at your fingertips.',
          'Card': 'Card',
          'Payment on Delivery': 'Payment on Delivery',
          'Conveniently pay for your order upon delivery using your preferred card, hassle-free.': 'Conveniently pay for your order upon delivery using your preferred card, hassle-free.',
          'Edit': 'Edit',
          'Preferred Delivery Date': 'Preferred Delivery Date',
          'Seamlessly adjust the delivery date to fit your schedule, giving you control over when your order arrives.': 'Seamlessly adjust the delivery date to fit your schedule, giving you control over when your order arrives.',
          'Delivery Location': 'Delivery Location',
          'Have the flexibility to edit the delivery location to ensure your order reaches you where it\'s most convenient.': 'Have the flexibility to edit the delivery location to ensure your order reaches you where it\'s most convenient.',
          'Contacting': 'Contacting',
          'Support': 'Support',
          'Easily reach out to customer support for any inquiries or assistance regarding your order.': 'Easily reach out to customer support for any inquiries or assistance regarding your order.',
          'Personalized': 'Personalized',
          'Notifications': 'Notifications',
          'Receive customized alerts and notifications tailored to your specific order, keeping you informed every step of the way.': 'Receive customized alerts and notifications tailored to your specific order, keeping you informed every step of the way.',
          'Support ': 'Support ',
          'Contact Us': 'Contact Us',
          'Our team will reach back to you as soon as possible once you are fill up the form': 'Our team will reach back to you as soon as possible once you are fill up the form',
          'Your Name': 'Your Name',
          'Phone Number': 'Phone Number',
          'Subject': 'Subject',
          'Your Message': 'Your Message',
          'Send': 'Send',
          'Track your shipment with': 'Track your shipment with',
          'Ship': 'Ship',
          'Blu': 'Blu',
          'Would you like to update the delivery address for your order?': 'Would you like to update the delivery address for your order?',
          'Delivery Address:': 'Delivery Address:',
          'Address': 'Address',
          'Governorate': 'Governorate',
          'city': 'City',
          'Zone': 'Zone',
          'Landmark': 'Landmark',
          '© Copyright 2024 - ShipBlu Technology.': '© Copyright 2024 - ShipBlu Technology.',
          'All Rights Reserved.': 'All Rights Reserved.',
          '4 Nile Street,Giza, Egypt.': '4 Nile Street,Giza, Egypt.',
          'Company': 'Company',
          'About': 'About',
          'Products': 'Products',
          'News': 'News',
          'Articles': 'Articles',
          'BluCast': 'BluCast',
          'myBlu App': 'myBlu App',
          'Careers': 'Careers',
          'Legal': 'Legal',
          'Terms & Conditions': 'Terms & Conditions',
          'Enterprise Price Plans': 'Enterprise Price Plans',
          'For sales inquiries': 'For sales inquiries',
          'For customer service': 'For customer service',
          'Track Your Shipment': 'Track Your Shipment',
          'Privacy Policy': 'Privacy Policy',
          'Confirm Changes': 'Confirm Changes',
          'Edit Address': 'Edit Address',
          'Email': 'Email',
          'Home': 'Home',
          '4 Nile Street, Giza, Egypt.': '4 Nile Street, Giza, Egypt.',
          'on hold': 'On Hold',
          'Get back to your seller to resolve the issue': 'Get back to your seller to resolve the issue',
          'You will change the date of your order to': 'You will change the date of your order to',
          'Change': 'Change',
          'Cancel': 'Cancel',
          'Shipment Payment': 'Shipment Payment',
          'Your wallet mobile number': 'Your wallet mobile number',
          '100% secure payment processing for your shipment': '100% secure payment processing for your shipment',
          'Pay Now': 'Pay Now',
          'You will receive an SMS with instructions to pay for your shipment. After payment, please refresh the order page to update its status': 'You will receive an SMS with instructions to pay for your shipment. After payment, please refresh the order page to update its status',
          'Reschedule Delivery Date': 'Reschedule Delivery Date',
          'Yes, Reschedule': 'Yes, Reschedule',
          'Show QR Code': 'Show QR Code',
          'Disclamer': 'Disclamer',
          'Please don\'t share the QR code with the delivery agent before his arrival.': 'Please don\'t share the QR code with the delivery agent before his arrival.',
          'The delivery agent will scan the QR code directly from your device upon arrival.Sharing the QR code with anyone is under your responsibility.': 'The delivery agent will scan the QR code directly from your device upon arrival.Sharing the QR code with anyone is under your responsibility.',
          'I understand': 'I understand',
          'The order was already delivered': 'The order was already delivered',
          'This information will appear once the order is picked up but before it is out for delivery.': 'This information will appear once the order is picked up but before it is out for delivery.',
          'Receive now .. Pay later!': 'Receive now .. Pay later!',
          'Download myBlu Now': 'Download myBlu Now',
          'Save QR code': 'Save QR code',
          'Customer rescheduled': 'You have asked the courier to reschedule the order to',
          'Customer refused to accept the shipment': ' You refused to accept the order',
          'Customer\'s phone is turned off': 'The courier couldn\'t reach you as your phone is switched off',
          'Wrong zone': 'The zone on your address was wrong. We will reroute it.',
          'No one at home': 'No one was available to receive the order.',
          'Customer is avoiding the shipment': 'you avoided receiving the shipment.',
          'Phone number is not related to the customer': ' phone number is not related to you',
          'Customer wants to Change Address': 'You have requested to change the address to',
          'Out Of Zone': 'Your location is currently not within our delivery service area.',
          'Customer Wants to open the package': 'You requested to open the package before accepting the delivery.',
          'Verification code is unavailable': 'You didn\'t receive the QR code',
          'Customer doesn\'t have enough money': ' You didn\'t have enough cash to receive the shipment',
          'Shipment doesn\'t follow the packaging guidelines': 'Your shipment was not properly packaged according to guidelines from the seller\'s side',
          'Wrong CoD': ' The cod on the shipment was wrong',
          'Customer is not answering our calls': 'We couldn\'t reach you to arrange delivery.',
          'Delivery Attempt at ': 'Delivery Attempt at ',
          'Delivery is delayed by 1 day to adjust it to the correct zone.': 'Delivery is delayed by 1 day to adjust it to the correct zone.',
          'Shipper Name': 'Shipper Name',
          'Shipment Timeline': 'Shipment Timeline',
          'Packages QR Codes': 'Packages QR Codes',
          'Edit Delivery Address': 'Edit Delivery Address',
          'To be determined after picking up your order': 'To be determined after picking up your order'
        },
      },
      ar: {
        translation: {
          'القاهرة - Cairo': 'القاهرة',
          'Cairo - القاهرة': 'القاهرة',
          'الاسكندرية - Alexandria': 'الاسكندرية',
          'الساحل الشمالي - North Coast': 'الساحل الشمالى',
          'الدقهلية  - Al-Dakahlia': 'الدقهلية',
          'القليوبية - Qalyubia': 'القليوبية',
          'المنوفية - Monoufia': 'المنوفية',
          'El Gharbia - الغربية': 'الغربية',
          'البحيرة - Al-Beheira': 'البحيرة',
          'Kafr El Sheikh - كفر الشيخ': 'كفر الشيخ',
          'اسيوط -Assiut': 'اسيوط',
          'الفيوم - Fayoum': 'الفيوم',
          'بني سويف - Beni Suef': 'بني سويف',
          'الاسماعيلية - Ismailiya': 'الاسماعيلية',
          'السويس - Suez': 'السويس',
          'البحر الأحمر- Al-Bahr Al-Ahmar': 'البحر الأحمر',
          'القاهرة الجديدة - New Cairo': 'القاهرة الجديدة',
          'وسط البلد - Downtown Cairo': 'وسط البلد',
          'اكتوبر - October': 'اكتوبر',
          'وسط البلد والجمرك - Downtown Alex & Gomrok': 'وسط البلد والجمرك',
          'Agamy & El-Amreya - العجمي والعامرية': 'العجمي والعامرية',
          'شرق الاسكندرية والمنتزة - East Alex & Montazah': 'شرق الاسكندرية والمنتزة',
          'الساحل - Sahel': 'الساحل',
          'المنصورة - Mansoura': 'المنصورة',
          'بنها - Banha': 'بنها',
          'Shebeen Al Kom - شبين الكوم': 'شبين الكوم',
          'Mahala El Kobra - المحلة الكبري': 'المحلة الكبري',
          'طنطا - Tanta': 'طنطا',
          'دمنهور - Damanhour': 'دمنهور',
          'اسيوط - Assiut': 'اسيوط',
          'الغردقة - Hurghada': 'الغردقة',
          'El Gouna - الجونة': 'الجونة',
          'التجمع الثالث - 3rd Settlement': 'التجمع الثالث',
          'التجمع الخامس - 5th Settlement': 'التجمع الخامس',
          '5th Settlement - District No 1': '5th Settlement - District No 1',
          '5th Settlement - District No 2': '5th Settlement - District No 2',
          '5th Settlement - District No 3': '5th Settlement - District No 3',
          '5th Settlement - District No 4': '5th Settlement - District No 4',
          '5th Settlement - District No 5': '5th Settlement - District No 5',
          'الشويفات - El-Shouyfat': 'الشويفات',
          'الدبلوماسيين - El-Diplomasyeen': 'الدبلوماسيين',
          'القطامية - El-Kattameya': 'القطامية',
          'ارابيلا - Arabella': 'ارابيلا',
          'غرب الجولف - Gharb El Golf': 'غرب الجولف',
          'Al Rehab - الرحاب': 'الرحاب',
          'الياسمين 1 - El-Yasmeen 1': 'الياسمين 1',
          'الياسمين 2 - El-Yasmeen 2': 'الياسمين 2',
          'الياسمين 3 - El-Yasmeen 3': 'الياسمين 3',
          'الياسمين 4 - El-Yasmeen 4': 'الياسمين 4',
          'الياسمين 5 - El-Yasmeen 5': 'الياسمين 5',
          'الياسمين 6 - El-Yasmeen 6': 'الياسمين 6',
          'الياسمين 7 - El-Yasmeen 7': 'الياسمين 7',
          'الياسمين 8 - El-Yasmeen 8': 'الياسمين 8',
          'البنفسج 1 - El-Banafseg 1': 'البنفسج 1',
          'البنفسج 2 - El-Banafseg 2': 'البنفسج 2',
          'البنفسج 3 - El-Banafseg 3': 'البنفسج 3',
          'البنفسج 4 - El-Banafseg 4': 'البنفسج 4',
          'البنفسج 5 - El-Banafseg 5': 'البنفسج 5',
          'البنفسج 6 - El-Banafseg 6': 'البنفسج 6',
          'البنفسج 7 - El-Banafseg 7': 'البنفسج 7',
          'البنفسج 8 - El-Banafseg 8': 'البنفسج 8',
          'البنفسج 9 - El-Banafseg 9': 'البنفسج 9',
          'البنفسج 10 - El-Banafseg 10': 'البنفسج 10',
          'البنفسج 11 - El-Banafseg 11': 'البنفسج 11',
          'البنفسج 12 - El-Banafseg 12': 'البنفسج 12',
          'التجمع الأول - 1st Settlement': 'التجمع الأول',
          '1st Settlement - North Youth Housing': '1st Settlement - North Youth Housing',
          '2nd Settlement - South Youth Housing': '2nd Settlement - South Youth Housing',
          'كايرو فستيفال سيتي - Cairo Festival City': 'كايرو فستيفال سيتي',
          'شرق الأكاديمية - Sharq El Academia': 'شرق الأكاديمية',
          'جنوب الاكاديمية - Ganoob Al Academia - Area A': 'جنوب الاكاديمية',
          'جنوب الاكاديمية - Ganoob Al Academia - Area B': 'جنوب الاكاديمية',
          'جنوب الاكاديمية - Ganoob Al Academia - Area C': 'جنوب الاكاديمية',
          'جنوب الاكاديمية - Ganoob Al Academia - Area D': 'جنوب الاكاديمية',
          'جنوب الاكاديمية - Ganoob Al Academia - Area\u00a0E': 'جنوب الاكاديمية',
          'جنوب الاكاديمية - Ganoob Al Academia - Area F': 'جنوب الاكاديمية',
          'منطقة المستثمرين الجنوبية - South Investors Area': 'منطقة المستثمرين الجنوبية',
          'منطقة المسثمرين الشمالية - North Investors Area': 'منطقة المسثمرين الشمالية',
          'الأندلوس - El Andalous': 'الأندلوس',
          'اللوتس - El-Lotus': 'اللوتس',
          'النرجس 1 - El-Narges 1': 'النرجس 1',
          'النرجس 2 - El-Narges 2': 'النرجس 2',
          'النرجس 3 - El-Narges 3': 'النرجس 3',
          'النرجس 4 - El-Narges 4': 'النرجس 4',
          'النرجس 5 - El-Narges 5': 'النرجس 5',
          'النرجس 6 - El-Narges 6': 'النرجس 6',
          'النرجس 7 - El-Narges 7': 'النرجس 7',
          'النرجس 8 - El-Narges 8': 'النرجس 8',
          'المنطقة الأولي - Area 1': 'المنطقة الأولي',
          'المنطقة الثالثة - Area 3': 'المنطقة الثالثة',
          'أبو الهول - Abo El Houl': 'أبو الهول',
          'العاشر من رمضان - 10th of Ramadan': 'العاشر من رمضان',
          'El Shorouk - الشروق': 'الشروق',
          'مدينة المستقبل - Future City': 'مدينة المستقبل',
          'مدينتي - Madinaty': 'مدينتي',
          'العبور - Obour': 'العبور',
          'مدينة الطلائع - El Talae3 City': 'مدينة الطلائع',
          'هيلوبوليس الجديدة - New Heliopolis City': 'هيلوبوليس الجديدة',
          'مدينة بدر - Badr City': 'مدينة بدر',
          'جمعية أحمد عرابي - Ahmed Oraby Association': 'جمعية أحمد عرابي',
          'Al Manial - المنيل': 'المنيل',
          'جاردن سيتي - Garden City': 'جاردن سيتي',
          'الزمالك - Zamalek': 'الزمالك',
          'Masr El Kadema - مصر القديمة': 'مصر القديمة',
          'عابدين - Abdeen': 'عابدين',
          'بولاق - Bulaq': 'بولاق',
          'العجوزة - Agouza': 'العجوزة',
          'الدقي - Dokki': 'الدقي',
          'الجيزة - Giza': 'الجيزة',
          'Giza - الجيزة': 'الجيزة',
          'Damanhour - دمنهور': 'دمنهور',
          'المهندسين - Mohandeseen': 'المهندسين',
          'ميت عقبة - Mit Akaba': 'ميت عقبة',
          'Ard El Lewa - ارض اللوا': 'ارض اللوا',
          'فيصل - Faisal': 'فيصل',
          'الهرم - Haram': 'الهرم',
          'حدائق الأهرام - Hadayek El Haram': 'حدائق الأهرام',
          'امبابة - Imbaba': 'امبابة',
          'El Matareya - المطرية': 'المطرية',
          'El Qobbah - القبة': 'القبة',
          'Al Marj - المرج': 'المرج',
          'Road El Farag - روض الفرج': 'روض الفرج',
          'العباسية - Abbassia': 'العباسية',
          'Al Wayli - الوايلي': 'الوايلي',
          'Al Daher': 'Al Daher',
          'شبرا - Shubra': 'شبرا',
          'Shubra El Kheima - شبرا الخيمة': 'شبرا الخيمة',
          'Al Muqattam - المقطم': 'المقطم',
          'الهضبة الوسطي - El-Hadaba El-Wosta': 'الهضبة الوسطى',
          'حلوان - Helwan': 'حلوان',
          'المعادي - Maadi': 'المعادى',
          'شمال القاهرة - Uptown Cairo': 'شمال القاهرة',
          'الزيتون - El-Zaytoun': 'الزيتون',
          'جسر السويس - Gesr elsuez': 'جسر السويس',
          'عين شمس - Ain Shams': 'عين شمس',
          'مدينة نصر - Nasr City': 'مدينة نصر',
          'شيراتون - Sheraton': 'شيراتون',
          'مصر الجديدة - Heliopolis': 'مصر الجديدة',
          'Abu Rawash - ابورواش': 'ابورواش',
          'Alex Desert Road (Before Toll Station)': 'Alex Desert Road (Before Toll Station)',
          'Alex Desert Road (After Toll Station)': 'Alex Desert Road (After Toll Station)',
          'New Giza': 'New Giza',
          'القرية الذكية - Smart Village': 'القرية الذكية',
          'Pyramids Heights': 'Pyramids Heights',
          'Garana Residence': 'Garana Residence',
          'Azizia Villas': 'Azizia Villas',
          'Katr El Nada': 'Katr El Nada',
          'دريم لاند - Dream Land': 'دريم لاند',
          'حدايق الاهرام - Pyramids Gardens': 'حدائق الاهرام',
          '6th of October City - اكتوبر': 'اكتوبر',
          '6th of October City - District 1': '6th of October City - District 1',
          '6th of October City - District 2': '6th of October City - District 2',
          '6th of October City - District 3': '6th of October City - District 3',
          '6th of October City - District 4': '6th of October City - District 4',
          '6th of October City - District 5': '6th of October City - District 5',
          '6th of October City - District 6': '6th of October City - District 6',
          '6th of October City - Al Bashayer District': '6th of October City - Al Bashayer District ',
          '6th of October City - District 8': '6th of October City - District 8',
          '6th of October City - District 10': '6th of October City - District 10',
          '6th of October City - District 11': '6th of October City - District 11',
          '6th of October City - District 12': '6th of October City - District 12',
          '6th of October City - Youth Residence': '6th of October City - Youth Residence',
          '6th of October City - Al Motameyz District': '6th of October City - Al Motameyz District',
          '6th of October City - West Somid': '6th of October City - West Somid',
          'Sheikh Zayed City - الشيخ زايد': 'الشيخ زايد',
          'Sheikh Zayed City - El Rabwa Compound': 'Sheikh Zayed City - El Rabwa Compound ',
          'Sheikh Zayed City - Westown Residence': 'Sheikh Zayed City - Westown Residence',
          'Sheikh Zayed City - Sodic Allegria': 'Sheikh Zayed City - Sodic Allegria',
          'Sheikh Zayed City - Casa Compound': 'Sheikh Zayed City - Casa Compound',
          'Sheikh Zayed City - Beverly Hills Zayed': 'Sheikh Zayed City - Beverly Hills Zayed',
          'Sheikh Zayed City - District 1': 'Sheikh Zayed City - District 1',
          'Sheikh Zayed City - District 2': 'Sheikh Zayed City - District 2',
          'Sheikh Zayed City - District 3': 'Sheikh Zayed City - District 3',
          'Sheikh Zayed City - District 4': 'Sheikh Zayed City - District 4',
          'Sheikh Zayed City - District 5': 'Sheikh Zayed City - District 5',
          'Sheikh Zayed City - District 7': 'Sheikh Zayed City - District 7',
          'Sheikh Zayed City - District 8': 'Sheikh Zayed City - District 8',
          'Sheikh Zayed City - District 9': 'Sheikh Zayed City - District 9',
          'Sheikh Zayed City - District 10': 'Sheikh Zayed City - District 1',
          'Sheikh Zayed City - District 11': 'Sheikh Zayed City - District 11',
          'Sheikh Zayed City - District 12': 'Sheikh Zayed City - District 12',
          'Sheikh Zayed City - District 14': 'Sheikh Zayed City - District 14',
          'Sheikh Zayed City - District 16': 'Sheikh Zayed City - District 16',
          'El Hadara - الحضرة': 'الحضرة',
          'El Labban - اللبان': 'اللبان',
          'El Shatby - الشاطبي': 'الشاطبى',
          'كرموز - Karmoz': 'كرموز',
          'Kom El Deka - كوم الدقة': 'كوم الدقة',
          'Mahatet El Raml - محطة الرمل': 'محطة الرمل',
          'محرم بيك - Moharam Bek': 'محرم بيك',
          'وسط البلد - Downtown Alexandria': 'وسط البلد',
          'El Atareen - العطارين': 'العطارين',
          'El Azarita - الازاريطة': 'الازاريطة',
          'El Ibrahimiya - الابراهيمية': 'الابراهيمية',
          'بحري - Bahary': 'بحرى',
          'El Anfoushi - الانفوشي': 'الانفوشى',
          'El Gomrok - الجمرك': 'الجمرك',
          'El Mansheya - المنشية': 'المنشية',
          'El Qabary - القباري': 'القبارى',
          'El Wardeyan - الورديان': 'الورديان',
          'العجمي - Agamy': 'العجمى',
          'أبو يوسف - Abou Youssef': 'ابو يوسف',
          'العامرية - El-Amreya': 'العامرية',
          'البيطاش - El-Bitash': 'البيطاش',
          'الديخيلة - El-Dekhela': 'الديخيلة',
          'الهانوفيل - El-Hanoveel': 'الهانوفيل',
          'المكس - El-Max': 'المكس',
          'أكتوبر - October': 'اكتوبر',
          'باكوس - Bakos': 'باكوس',
          'بولكلي - Bolkly': 'بولكلي',
          'كامب شيزار - Camp Chezar': 'كامب شيزار',
          'كليوباترا - Cleopatra': 'كليوباترا',
          'El Saraya - السراية': 'السراية',
          'فليمنج - Fleming': 'فليمنج',
          'جناكلس - Gianaclis': 'جناكلس',
          'جليم - Glim': 'جليم',
          'كقر عبده - Kafr Abdu': 'كقر عبده',
          'لوران - Louran': 'لوران',
          'رشدي - Roshdy': 'رشدي',
          'سابا باشا - Saba Basha': 'سابا باشا',
          'سان ستيفانو - San Stefano': 'سان ستيفانو',
          'سيدي جابر - Sidi Gaber': 'سيدي جابر',
          'سموحة - Smouha': 'سموحة',
          'سبورتنج - Sporting': 'سبورتنج',
          'ستانلي - Stanley': 'ستانلي',
          'ثروت - Tharwat': 'ثروت',
          'زيزينيا - Zezenia': 'زيزينيا',
          'Abu Kir - ابوقير': 'ابوقير',
          'El Asafra - العصافرة': 'العصافرة',
          'El Maamora - المعمورة': 'المعمورة',
          'El Mandara - المندرة': 'المندرة',
          'El Montaza - المنتزة': 'المنتزة',
          'El Soyof - السيوف': 'السيوف',
          'ميامي - Miami': 'ميامي',
          'سيدي بشر - Sidi Bishr': 'سيدي بشر',
          'فكتوريا - Victoria': 'فكتوريا',
          'عزبة الشال': 'عزبة الشال',
          'مدينة مبارك': 'مدينة مبارك',
          'سندوب': 'سندوب',
          'مدينة الفردوس': 'مدينة الفردوس',
          'مدينة السلام': 'مدينة السلام',
          'Madinet Al-Salam - مدينة السلام': 'مدينة السلام',
          '6 October - السادس من أكتوبر': 'السادس من أكتوبر',
          'New Cities - المدن الجديدة': 'المدن الجديدة',
          'حي الجامعة': 'حي الجامعة',
          'ميت خميس': 'ميت خميس',
          'طلخا': 'طلخا',
          'ميت عنتر': 'ميت عنتر',
          'منية سندوب': 'منية سندوب',
          'بلطيم - Balteem': 'بلطيم',
          'بيلا - Bela': 'بيلا',
          'البرلس - Borolles': 'البرلس',
          'دسوق - Desouq': 'دسوق',
          'الرياض - El-Reyad': 'الرياض',
          'فوه - Fuwwah': 'فوه',
          'حمول - Hamool': 'حمول',
          'كفر الشيخ - Kafr Alsheikh': 'كفر الشيخ',
          'مطوبس - Mutubas': 'مطوبس',
          'قلين - Qeleen': 'قلين',
          'سيدي سالم - Sidi Salem': 'سيدى سالم',
          'ابنوب - Abanoub': 'ابنوب',
          'Abo Teg - ابوتيج': 'ابو تيج',
          'ديروط - Dayrout': 'ديروط',
          'El Badary - البداري': 'البداري',
          'El Fath - الفتح': 'الفتح',
          'El Ghanaym - الغنايم': 'الغنايم',
          'El Qawsaia - القوصية': 'القوصية',
          'منفلوط - Manfalout': 'منفلوط',
          'اسيوط الجديدة - New Assuit': 'اسيوط الجديدة',
          'صدفا - Sadfa': 'صدفا',
          'ساحل سليم - Sahel Selim': 'ساحل سليم',
          'اطسا - Atsa': 'اطسا',
          'ابشواي - Ebsheway': 'ابشوى',
          'طمية - Tamya': 'طمية',
          'يوسف الصديق - Yousef Elsedeq': 'يوسف الصديق',
          'ببا - Biba': 'ببا',
          'الفشن - Elfashn': 'الفشن',
          'الواسطى - Elwasty': 'الواسطى',
          'إهناسيا - Ihnasya': 'إهناسيا',
          'ناصر - Nasser': 'ناصر',
          'بني سويف الجديدة - New Beni Suef': 'بنى سويف الجديدة',
          'سمسطا - Samsta': 'سمسطا',
          'أبو عطوة - Abu Atwa': 'أبو عطوة',
          'أبو خليفة - Abu Khalifah': 'أبو خليفة',
          'أبو صوير - Abu Soweir': 'أبو صوير',
          'أبو سلطان - Abu sultan': 'أبو سلطان',
          'التل الكبير - Al Tall Al kabir': 'التل الكبير',
          'القصاصين - El Kasasin': 'القصاصين',
          'الصالحية الجديدة - El Saleheya Al gadida': 'الصالحية الجديدة',
          'فايد - Fayed': 'فايد',
          'الحي الأول - Hay 1': 'الحى الأول',
          'الحي الثاني - Hay 2': 'الحى الثانى',
          'الحي الثالث - Hay 3': 'الحى الثالث',
          'الكيلو 11 - Kilo 11': 'الكيلو 11',
          'قنطرة غرب - Qantara Gharab': 'قنطرة غرب',
          'قنطرة شرق - Qantara Shark': 'قنطرة شرق',
          'سرابيوم - Sarabium': 'سرابيوم',
          'أبو رديس - Abu Rudeis': 'أبو رديس',
          'أبو زنيمة - Abu Zenima': 'أبو زنيمة',
          'منطقة السويس - Al Suez District': 'منطقة السويس',
          'العدبية - Al-Adabya': 'العدبية',
          'عتاقة - Ataka District': 'عتاقة',
          'السلام - El Salam District': 'السلام',
          'الأربعين - El-Arbeen District': 'الأربعين',
          'الجناين - Elganaien District': 'الجناين',
          'فيصل - Faisal District': 'فيصل',
          'El-Hawamdeya - الحوامدية': 'الحوامدية',
          'ElBadrasheen - البدرشين': 'البدرشين',
          'الحوامدية - El-Hawamdeya': 'الحوامدية',
          'البدرشين - ElBadrasheen': 'البدرشين',
          'Search/copy your location..': 'بحث/نسخ موقعك ..',
          'Locate me!': 'حدد مكانى!',
          'From: ': 'من: ',
          'To: ': 'الى: ',
          'we have an eye on your shipment': 'بنتابع شحنتك مكانك',
          'Here you can track your shipment, and reschedule your delivery date.': 'من هنا ممكن تتابع شحنتك، تغيير يوم أو ميعاد الاستلام.',
          'Estimated Delivery Date': 'يوم التسليم المتوقع',
          'Locate on map': 'حدد موقعك على الخريطة',
          'Wanna decide when to expect your order?': 'محتاج تقرر امتى بالظبط تتوقع وصول شحنتك؟',
          'Kindly choose an appropriate time slot that fits your availability!': 'اختار الوقت المناسب لك والمتاح لاستلام شحنتك!',
          'Wanna receive your order on a different day?': 'محتاج تغير يوم الاستلام ليوم تاني؟',
          'Kindly choose another day to receive your order on!': 'اختار يوم تاني مناسب اكتر من هنا!',
          'Locate your Address!': 'حدد موقعك!',
          'Help us find your address below so that we don\'t have to call you for directions!': 'ساعدنا نلاقي مكانك بسهوله من غير مكالمات نسألك فيها عن مكان الاستلام!',
          'You can select more than one!': 'تقدر تختار اكتر من فترة زمنية!',
          'You\'ve rescheduled your shipment\'s delivery window to:': 'انت غيرت ميعاد استلام شحنتك ل:',
          'You\'ve rescheduled your delivery date to:': 'انت غيرت يوم استلام شحنتك ل:',
          'Your location is:': 'عنوانك:',
          'Your location is now updated': 'تم تحديث مكان استلام الطلب',
          'Save Address': 'حفظ العنوان',
          'what3words (optional)': 'عنوان٣كلمات (أختياري)',
          'Enter your shipment tracking number': 'أدخل رقم تتبع شحنتك',
          'Track': 'تتبع',
          'The tracking number field format is invalid': 'رقم التتبع غير صحيح',
          'Between': 'بين',
          'No delivery window chosen': 'لم يتم اختيار نافذة التسليم',
          'or': 'او',
          'And': 'و',
          'Picked up': 'تم استلام الطلب',
          'En Route': 'في مركز التوزيع',
          'Out for Delivery': 'طلبك في طريقه إليك',
          'Delivered': 'تم تسليم الطلب',
          'Out for Return': 'طلبك فى طريقه للتاجر',
          'Returned': 'تم ارجاع الطلب',
          'Tracking number does not exist!': 'رقم التتبع غير موجود!',
          'You can change your order information after': 'يمكنك تغيير معلومات الاوردر الخاص بك بعد',
          'entering the': 'إدخال',
          'pin code': 'الرمز السري',
          'provided in the': 'المتواجد في رساله الواتس اب',
          'Whatsapp confirmation message': 'الخاصه بك.',
          'You can change the details of your order through the link sent to your WhatsApp number , or by contacting the shipper': 'يمكنك تغيير تفاصيل طلبك من خلال الرابط المرسل علي الواتس اب لرقمك المسجل لدينا او من خلال التواصل مع المرسل',
          'contacting the shipper': 'مع المرسل',
          'Got It': 'فهمتك',
          'Change order details': 'تغيير تفاصيل الطلب',
          'Created': 'تم الاضافة',
          'Packages': 'الطرود',
          'Shipper': 'المورد',
          'Save to your phone': 'حفظ على هاتفك',
          'Our agent will scan this QR Code from your device once he reaches your location.': '.سيقوم وكيلنا بمسح رمز الاستجابة السريعة هذا من جهازك بمجرد وصوله إلى موقعك',
          'Do not send it to him before his arrival.': '.لا ترسله له قبل وصوله',
          'Valu installments is now available at your doorstep': 'قسط بڤاليو دلوقتي عند استلام الاوردر',
          'Your customers can now pay in installments for up to 60 months using Valu through myBlu app.': 'دلوقتي عميلك يقدر يدفع بڤاليو ويقسط لحد 60 شهر علي ابليكشن myBlu',
          'Track, Pay, and Edit:': 'تتبع، عدل، وادفع:',
          'Essentials': 'كل إللي تحتاجة ',
          'for Your Order Management': 'لإدارة شُحنتك',
          'Instant': 'فوريا',
          'Tracking Updates': 'حدث شحنتك',
          'Stay in the loop with real-time updates on your order\'s shipping progress, right at your fingertips.': 'خليك على اطلاع بالتحديثات اللي بتحصل لشُحنتك لحظة بلحظة.',
          'Card': 'بالكارت',
          'Payment on Delivery': 'ادفع عند التوصيل',
          'Conveniently pay for your order upon delivery using your preferred card, hassle-free.': 'ادفع قيمة شُحنتك عند التوصيل باستخدام الكارت اللي تفضله، من غير أي مشاكل أوتأخير.',
          'Edit': 'تعديل',
          'Preferred Delivery Date': 'تاريخ التوصيل المفضل',
          'Seamlessly adjust the delivery date to fit your schedule, giving you control over when your order arrives.': 'إختار تاريخ التوصيل بكل سلاسة علشان يناسب جدولك.',
          'Delivery Location': 'مكان التوصيل',
          'Have the flexibility to edit the delivery location to ensure your order reaches you where it\'s most convenient.': 'استمتع بالمرونة في تعديل مكان التوصيل علشان تتأكد إن شُحنتك هتوصلك في المكان اللي يكون أكتر راحة لك.',
          'Contacting': 'التواصل',
          'Support': 'مع الدعم',
          'Easily reach out to customer support for any inquiries or assistance regarding your order.': 'اتواصل بسهولة مع خدمة العملاء لأي استفسارات أو مساعدة تخص طلبك.',
          'Personalized': ' خصص لشُحتنك',
          'Notifications': 'إشعارات',
          'Receive customized alerts and notifications tailored to your specific order, keeping you informed every step of the way.': 'استقبل تنبيهات وإشعارات مخصصة تناسب شُحنتك بالتحديد، علشان تكون على علم بكل خطوة على الطريق.',
          'Support ': ' الدعم',
          'Contact Us': 'تواصل معنا',
          'Our team will reach back to you as soon as possible once you are fill up the form': 'فريق الدعم هيرد عليك في أقرب وقت ممكن لمساعدتك',
          'Your Name': 'اسمك',
          'Phone Number': 'رقم التليفون',
          'Subject': 'العنوان',
          'Your Message': 'رسالتك',
          'Send': 'ارسل',
          'Track your shipment with': 'تتبع شحنتك مع',
          'Ship': 'شيب ',
          'Blu': 'بلو',
          'Would you like to update the delivery address for your order?': 'هل تريد تعديل عنوان التوصيل لشحنتك ؟',
          'Delivery Address:': 'عنوان التوصيل:',
          'Address': 'العنوان',
          'Governorate': 'المحافظه',
          'city': 'المدينه',
          'Zone': 'المنطقه',
          'Landmark': 'علامه مميزه',
          '© Copyright 2024 - ShipBlu Technology.': '© شيب بلو ٢٠٢٤.',
          'All Rights Reserved.': 'جميع الحقوق محفوظة.',
          '4 Nile Street,Giza, Egypt.': '4 شارع النيل، الجيزة، مصر.',
          'Company': 'الشركه',
          'About': 'عن شيب بلو',
          'Products': 'منتجات',
          'News': 'أخبار',
          'Articles': 'مقالات',
          'BluCast': 'بلوكاست',
          'myBlu App': 'تطبيق ماى يلو',
          'Careers': 'وظائف',
          'Legal': 'قانوني',
          'Terms & Conditions': 'الشروط والأحكام',
          'Enterprise Price Plans': 'خطط أسعار الشركات',
          'For sales inquiries': 'للتواصل مع فريق المبيعات',
          'For customer service': 'للتواصل مع فريق الدعم',
          'Track Your Shipment': 'تتبع شحنتك',
          'Privacy Policy': 'سياسة الخصوصية',
          'Confirm Changes': 'تاكيد التغير',
          'Edit Address': 'تعديل العنوان',
          'Email': 'البريد الالكتروني',
          'Home': 'الصفحة الرئيسية',
          '4 Nile Street, Giza, Egypt.': '4 شارع النيل, الجيزة, مصر.',
          'on hold': 'في الانتظار',
          'Get back to your seller to resolve the issue': 'ارجع إلى البائع لحل المشكلة',
          'You will change the date of your order to': 'سوف تقوم بتغيير تاريخ طلبك إلى',
          'Change': 'تغيير',
          'Cancel': 'إلغاء',
          'Shipment Payment': 'دفع الشحنة',
          'Your wallet mobile number': 'رقم المحفظة الالكترونية',
          '100% secure payment processing for your shipment': 'عملية الدفع لشحنتك آمنة بنسبة 100%',
          'Pay Now': 'ادفع الاَن',
          'You will receive an SMS with instructions to pay for your shipment. After payment, please refresh the order page to update its status': 'سوف تصلك رسالة قصيرة بخطوات الدفع عن طريق المحفظة الالكترونية. من فضلك، قم بالعودة للتطبيق وتحديث صفحة الشحنة لتظهر حالة الدفع',
          'Reschedule Delivery Date': 'إعادة جدولة تاريخ التسليم',
          'Yes, Reschedule': 'نعم، إعادة جدولة',
          'Show QR Code': 'اظهر رمز (QR)',
          'Disclamer': 'تحذير',
          'Please don\'t share the QR code with the delivery agent before his arrival.': 'يرجي عدم مشاركة رمز الاستجابة السريع (QR) مع المندوب قبل وصوله.',
          'The delivery agent will scan the QR code directly from your device upon arrival.Sharing the QR code with anyone is under your responsibility.': 'سيقوم المندوب بمسح رمز الاستجابة السريع (QR) مباشرة من جهازك عند الوصول اليك ومشاركته مع المندوب قبل ذلك يكون تحت مسؤوليتك الشخصية.',
          'I understand': 'موافق',
          'The order was already delivered': 'تم توصيل الطلب',
          'This information will appear once the order is picked up but before it is out for delivery.': 'هذه المعلومات سوف تظهر بمجرد استلام الطلب لكن قبل ان يكون خارج للتوصيل.',
          'Receive now .. Pay later!': 'استلم دلوقتي.. ادفع بعدين!',
          'Download myBlu Now': 'حمل تطبيق myBlu',
          'Save QR code': 'حفظ رمز الاستجابة السريع (QR)',
          'Delivery Attempt at ': 'محاوله توصيل في ',
          'Delivery is delayed by 1 day to adjust it to the correct zone.': 'التوصيل ماجل لمده يوم 1 لاجل تعديل المنطقه.',
          'Shipper Name': 'اسم التاجر',
          'Shipment Timeline': 'الجدول الزمني للشحنة',
          'Packages QR Codes': ' رموز الطرود (QR)',
          'Edit Delivery Address': 'تعديل عنوان التوصيل',
          'To be determined after picking up your order': 'سوف يتم تحديده بعد استلام اوردرك',
          'Customer rescheduled': 'طلبت تأجيل الشحنة مع المندوب ليوم',
          'Customer refused to accept the shipment': 'رفضت استلام الشحنة',
          'Customer\'s phone is turned off': 'هاتفك المحمول مغلق',
          'Wrong zone': 'تم تعيين منطقة خاطئة لشحنتك',
          'No one at home': 'لم يكن هناك احد متاح للاستلام.',
          'Customer is avoiding the shipment': 'لا تريد استلام الشحنة.',
          'Phone number is not related to the customer': ' رقم هاتفك المحمول خطأ',
          'Customer wants to Change Address': 'طلبت تغير العنوان الي',
          'Out Of Zone': 'موقعك الحالي خارج نطاق التوصيل لدينا.',
          'Customer Wants to open the package': 'طلبت فتح الشحنة قبل الاستلام.',
          'Verification code is unavailable': 'لم تتمكن من الحصول علي رمز الاستجابة السريع QR code',
          'Customer doesn\'t have enough money': 'مبلغ التحصيل غير متوافر معك حاليا',
          'Shipment doesn\'t follow the packaging guidelines': 'لم يتم تغليف شحنتك طبقا للارشادات وسوف يتم إرجاعها للتاجر',
          'Wrong CoD': 'يوجد خطأ في سعر الشحنة',
          'Customer is not answering our calls': 'لم نتمكن من التوصل اليك.',
        },
      },
    },
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;